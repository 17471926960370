import { Application } from '@hotwired/stimulus';

import analytics from '../util/analytics';

const application = Application.start();

// Configure Stimulus development experience
application.debug = window?.config?.releaseStage !== 'production';
window.Stimulus = application;

export { application };

analytics.init();
